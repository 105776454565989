import React from "react" // eslint-disable-line no-unused-vars
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import ImageHotspot from "../../../components/image-hotspot"
import ImageHotspots from "../../../components/image-hotspots"
import ImageHotspotsModalColumnsTemplate from "../../../components/image-hotspots-modal-columns-template"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import ReadMoreButton from "../../../components/read-more-button"
import VideoPlayer from "../../../components/video-player"

const HoelderlinLiebtNeckarPage = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        bueste: file(
          relativePath: { eq: "leichte-sprache/bueste-ausstellung.jpg" }
        ) {
          ...mediumImage
        }
        hotspotLeinwand: file(
          relativePath: { eq: "hoelderlin-liebt/hotspot-neckar-leinwand.jpg" }
        ) {
          ...mediumImage
        }
        hotspotBordeaux: file(
          relativePath: { eq: "hoelderlin-liebt/hotspot-bordeaux.jpg" }
        ) {
          ...mediumImage
        }
        hotspotGriechenland: file(
          relativePath: { eq: "hoelderlin-liebt/hotspot-griechenland.jpg" }
        ) {
          ...mediumImage
        }
        hotspotNuertingen: file(
          relativePath: { eq: "hoelderlin-liebt/hotspot-nuertingen.jpg" }
        ) {
          ...mediumImage
        }
        hotspotTuebingen: file(
          relativePath: { eq: "hoelderlin-liebt/hotspot-tuebingen.jpg" }
        ) {
          ...mediumImage
        }
        hotspotLauffen: file(
          relativePath: { eq: "hoelderlin-liebt/hotspot-lauffen.jpg" }
        ) {
          ...mediumImage
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-neckar.jpg" }
        ) {
          ...mediumImage
        }
        posterNeckar: file(
          relativePath: { eq: "hoelderlin-liebt/poster-neckar.jpg" }
        ) {
          ...largeImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt die Menschen"
        description="Die letzten 36 Jahre seines Lebens verbringt Hölderlin bei der Familie Zimmer in Tübingen."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… den Neckar",
                link: "/sonderausstellungen/hoelderlin-liebt/neckar",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt den Neckar</PageTitle>

          <Grid columns={[1, 2]} alignY="center">
            <Paragraph dropcap={true}>
              Hölderlin ist sein Leben lang gerne draußen.
              <br />
              Auch wenn es regnet. Auch wenn es schneit.
              <br />
              Er liebt den Wald mit den großen Bäumen. <br />
              Er geht durch die Felder.
              <br />
              Er kennt Wind und Wetter.
              <br />
              Und der Fluss Neckar begleitet Hölderlin ein Leben lang.
            </Paragraph>
            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                attribution="Jette von Bodecker"
                alt="Eine Illustration zeigt Hölderlin. Er läuft am Neckar entlang."
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Hölderlins Gedicht ›Der Neckar‹ in Gebärdensprache
          </Heading>
          <VideoPlayer
            src="hoelderlin-liebt/neckar"
            poster={data.posterNeckar}
          />
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Hölderlins Lebensstationen am Neckar
          </Heading>
          <Paragraph>
            Klicken Sie auf das <ReadMoreButton as="span" /> Symbol, um mehr zu
            lesen.
          </Paragraph>
          <ImageHotspots
            image={
              <Image
                image={data.hotspotLeinwand.childImageSharp.gatsbyImageData}
                alt="Eine Illustration zeigt Hölderlins Lebensstationen am Neckar."
              />
            }
          >
            <ImageHotspot bg="autumn" x={25} y={55}>
              <ImageHotspotsModalColumnsTemplate
                title="Lauffen"
                image={
                  <Image
                    image={data.hotspotLauffen.childImageSharp.gatsbyImageData}
                    alt="Eine Illustration zeigt die Neckarbrücke vor dem Kloster in Lauffen."
                  />
                }
              >
                <Paragraph>
                  Hölderlin wird im Jahr 1770 in Lauffen am Neckar geboren.
                </Paragraph>
              </ImageHotspotsModalColumnsTemplate>
            </ImageHotspot>
            <ImageHotspot bg="autumn" x={45} y={45}>
              <ImageHotspotsModalColumnsTemplate
                title="Nürtingen"
                image={
                  <Image
                    image={
                      data.hotspotNuertingen.childImageSharp.gatsbyImageData
                    }
                    alt="Eine Illustration zeigt die Altstadt von Nürtigen mit einem Kirchturm."
                  />
                }
              >
                <Paragraph>
                  Als Hölderlin 4 Jahre alt ist, zieht die Familie nach
                  Nürtingen. <br /> Mit seinen Geschwistern Karl und Rike spielt
                  Hölderlin oft am Neckar-Ufer.
                </Paragraph>
              </ImageHotspotsModalColumnsTemplate>
            </ImageHotspot>
            <ImageHotspot bg="autumn" x={55} y={55}>
              <ImageHotspotsModalColumnsTemplate
                title="Tübingen"
                image={
                  <Image
                    image={
                      data.hotspotTuebingen.childImageSharp.gatsbyImageData
                    }
                    alt="Eine Illustration zeigt den Hölderlinturm in Tübingen am Neckar."
                  />
                }
              >
                <Paragraph>
                  Mit 18 Jahren kommt Hölderlin zum ersten Mal nach Tübingen.{" "}
                  <br />
                  Er studiert im Evangelischen Stift.
                </Paragraph>
                <Paragraph>
                  Später kommt Hölderlin noch einmal nach Tübingen zurück.{" "}
                  <br />
                  Er ist krank. Geisteskrank. <br />
                  Im Krankenhaus wird er behandelt. <br />
                  Danach nimmt ihn die Familie Zimmer in ihrem Haus am Neckar
                  auf.
                </Paragraph>
              </ImageHotspotsModalColumnsTemplate>
            </ImageHotspot>
            <ImageHotspot bg="autumn" x={90} y={15}>
              <ImageHotspotsModalColumnsTemplate
                title="Griechenland"
                image={
                  <Image
                    image={
                      data.hotspotGriechenland.childImageSharp.gatsbyImageData
                    }
                    alt="Ein griechischer Tempel."
                  />
                }
              >
                <Paragraph>
                  Hölderlin war nie in Griechenland. <br />
                  Aber er wäre gerne dorthin gegangen. <br />
                  In seinen Gedichten schreibt er oft über Griechenland. <br />
                  Er mag die alten griechischen Dichter.
                </Paragraph>
              </ImageHotspotsModalColumnsTemplate>
            </ImageHotspot>
            <ImageHotspot bg="autumn" x={85} y={92}>
              <ImageHotspotsModalColumnsTemplate
                title="Bordeaux"
                image={
                  <Image
                    image={data.hotspotBordeaux.childImageSharp.gatsbyImageData}
                    alt="Eine alte französische Kathedrale."
                  />
                }
              >
                <Paragraph>
                  Nach dem Studium arbeitet Hölderlin als Hauslehrer. <br />
                  Er will die Welt kennenlernen. <br />
                  Dafür wandert er sogar bis nach Frankreich.
                </Paragraph>
              </ImageHotspotsModalColumnsTemplate>
            </ImageHotspot>
          </ImageHotspots>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtNeckarPage
