import React from "react"
import Stack from "./stack"
import Heading from "./heading"
import Container from "./container"

import PropTypes from "prop-types"
import Columns from "./columns"
import Column from "./column"

const ImageHotspotsModalColumnsTemplate = (props) => {
  const { title, image, children } = props
  return (
    <Container maxWidth="5xl">
      <Columns collapse={[true, false]} space={12}>
        <Column width={["100%", "1/2", "2/5"]}>{image}</Column>
        <Column>
          <Stack space={6}>
            <Heading level={3}>{title}</Heading>
            {children}
          </Stack>
        </Column>
      </Columns>
    </Container>
  )
}

ImageHotspotsModalColumnsTemplate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.node,
  children: PropTypes.node,
}

export default ImageHotspotsModalColumnsTemplate
